import React from 'react';
import styled from 'styled-components';
import Button from './Button';
import road from '../images/road.png';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url(${props => props.background});
  background-repeat: none;
  background-size: cover;
  background-position: bottom;
`;

const Title = styled.h3`
  display: block;
  text-align: center;
  font-size: 36px;
  margin-bottom: 20vh;
  font-family: Fjalla One;
  color: ${(props) => props.black ? 'black' : 'white'};
  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`;

const SubContainer = styled.div`
  width: auto;
  padding: 20px;
  height: auto;
  background: rgba(0, 0, 0, 0.3);
  margin-bottom: 20vh;
  @media screen and (max-width: 768px) {
    padding: 40px 20px;
  }
`;

const Subtitle = styled.h4`
  width: auto;
  font-size: 20px;
  margin: 0 auto;
  font-family: ${(props) => props.theme.fonts.excerpt};
  color: white;
  font-weight: 900;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: auto;
  position: relative;
  justify-content: center;
  button {
    margin: 20px 10px 0px 10px;
  }
  @media screen and (max-width: 768px) {
    .important {
      position: absolute;
      margin: 0;
      top: 8px;
    }
  }
`;

const LearnMoreFull = ({ black = false, background = road }) => {
  return (
    <Container background={background}>
      <Title black={black}>What is Your Purpose?</Title>
      <SubContainer>
        <Subtitle>
          This kind of question has many answers.
          <br/>
          Spin the wheel to find yours.
        </Subtitle>
        <ButtonContainer>
        <Button className="important" text={"Spin the wheel"}/>
        <Button className="secondary" text={"Learn more."} />
        </ButtonContainer>
      </SubContainer>
    </Container>
  )
}

export default LearnMoreFull;
