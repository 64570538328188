import React from 'react';

import Explore from '../components/Explore';
import HeaderMargin from '../components/HeaderMargin';
import Layout from '../components/layout';
import LearnMoreFull from '../components/LearnMoreFull';
import LearnMoreSmall from '../components/LearnMoreSmall';
import InfoPicBlock from '../components/InfoPicBlock';
import QuoteBlock from '../components/QuoteBlock';
import SEO from '../components/seo';
import Video from '../components/Video';

import { HomeInfo, HomeQuote } from '../content/home';
import path from '../images/path.jpg';

export default ({ data }) => {
  const posts = data.allWordpressPost.edges.map((edge) => edge.node);
  return (
    <Layout>
      <SEO title="Home" />
      <HeaderMargin />
      <Video src={'https://player.vimeo.com/video/427156841?title=0&byline=0&portrait=0'} />
      <QuoteBlock
        grayText={"Out of clutter, find"}
        redText={"simplicity"}
      >
        <HomeQuote />
      </QuoteBlock>
      <LearnMoreFull background={path}/>
      <InfoPicBlock info={HomeInfo}/>
      <LearnMoreSmall />
      <Explore posts={posts}/>
    </Layout>
  )
};

export const query = graphql`
  query {
    allWordpressPost(sort: {order: DESC, fields: date}, filter: {categories: {ne: 2136}}, limit: 3) {
      edges {
        node {
          id
          date
          content
          excerpt
          slug
          title
          jetpack_featured_media_url
        }
      }
    }
  }
`
