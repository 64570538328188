import React from 'react';
import styled from 'styled-components';
import carouselImage from '../../assets/IMG_0407.jpg';

const Container = styled.div`
  width: 100%;
  position: relative;
  button {
    display: ${props => props.hideButtons ? 'none' : 'block'}
  }
`;

const CarouselButton = styled.button`
  position: absolute;
  top: 45%;
  background: red;
  height: 75px;
  width: auto;
  left: ${props => props.left ? '-18px' : 'auto'};
  right: ${props => props.left ? 'auto' : '-18px'};
  text-align: ${props => props.left ? 'left' : 'right'};
  color: white;
  font-size: 24px;
  @media screen and (max-width: 768px) {
    top: 45%;
  }
  @media screen and (max-width: 480px) {
    top: 35%;
  }
`;

const Image = styled.img`
  width: 100%;
  max-height: 500px;
  height: 100%;
  object-fit: cover;
  object-position: top;
`;

const Carousel = ({ image = carouselImage }) => {
  return (
    <Container hideButtons>
      <CarouselButton left={true}>&#60;</CarouselButton>
      <Image src={image}/> 
      <CarouselButton left={false}>&#62;</CarouselButton>
    </Container> 
  )
};

export default Carousel;
