import React from 'react';
import bike from '../../assets/bike.jpg';

export const HomeInfo = {
  title: `What is the Wheel of Purpose?`,
  firstP: (
    <>
      In order for a wheel to work properly, it must be balanced. If it isn’t in a perfectly round circle, it won’t roll properly.
      <br />
      <br />
      Our lives are like that as well. If we aren’t in balance, we will experience more bumps and we might break down.
      <br />
      <br />
      So let's approach our lives as a wheel. Are you in balance?
    </>
  ),
  secondP: (
    <>
      When we discuss balance, it is important to discuss purpose. Purpose, by definition, is “the reason something is done or created or for which something exists."
      <br />
      <br />
      I believe everyone has a true purpose in life, but many of us don’t know what it is. The function of the wheel is to help us identify our purpose. Each piece of the wheel is essential. When we align our wheel, we’ll align our lives. Here’s a chance to spin the Wheel of Purpose and choose an area of your life to focus, reflect, and take action on.
    </>
  ),
  image: bike
}

export const HomeQuote = () => (
  <>
    Maybe you have a dream. Maybe you’ve been noticing nature more, so you're thinking about your life. Maybe there is a trip you want to take with someone you love. Or maybe you want a different job, a stronger body, or the ability to communicate more deeply.
    <br />
    <br />
    If you are here, you know that the process of seeking is as important as the answer you seek, and you have a lot of questions.
    <br />
    <br />
    Let’s start with:
    <br />
    <br />
    What is your purpose?
  </>
)

