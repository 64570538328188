import React from 'react';
import styled from 'styled-components';
import Carousel from './Carousel'
import Button from './Button';

const Container = styled.div`
  ${props => props.isGrid 
      ? `display: grid;
      grid-template-columns: 4fr 5fr;
      grid-template-rows: auto;
      grid-gap: 64px;`
      : `
      display: flex;
      flex-direction: column;
      * {
        margin: 16px 0;
      }`
  }
  width: 75%;
  max-width: 1200px;
  margin: 100px auto;
  @media screen and (max-width: 768px) {
    width: 85%;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    margin: 48px auto;
  }
`;

const Title = styled.h2`
  font-family: Helvetica;
  font-weight: 100;
  text-align: center;
  grid-area: 1 / 1 / 2 / 3;
`;

const FirstP = styled.p`
  font-family: ${(props) => props.theme.fonts.excerpt};
  grid-area: 2 / 1 / 3 / 3;
  font-size: 18px;
  color: #525252;
  font-weight: 300;
  line-height: 30px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    margin-top: 24px;
  }
`;

const SecondP = styled.p`
  font-family: ${(props) => props.theme.fonts.excerpt};
  grid-area: 4 / 2 / 5 / 3; 
  font-size: 18px;
  color: #525252;
  font-weight: 300;
  line-height: 30px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const CarouselContainer = styled.div`
  grid-area: 3 / 1 / 4 / 3;
  @media screen and (max-width: 768px) {
    margin-top: 24px;
  }
`;

const ProfilePhoto = styled.img`
  grid-area: 4 / 1 / 5 / 2; 
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const InfoPicBlock = ({ info }) => {
  return (
    <Container isGrid={!!info.secondImage}>
      <Title>{info.title}</Title>
      <FirstP>{info.firstP}</FirstP>
      <CarouselContainer>
        <Carousel image={info.image}/>
      </CarouselContainer>
      { !!info.secondImage ? <ProfilePhoto src={info.secondImage}/> : null }
      <SecondP>{info.secondP}</SecondP>
    </Container>
  )
}

export default InfoPicBlock;
